/*======================================
 6. Home-style-3 
 =======================================*/

 /* 6.1 wpo-about-area-s3  */

 .wpo-about-area-s3 {
	.wpo-about-img{
		padding-left: 80px;

		@media(max-width:1400px){
          padding-left: 30px;
		}
		@media(max-width:991px){
          padding-left: 0;
		}
	}
 }

 