
@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?f292c07b15c77b4e938a4b83b18f04e8") format("truetype"),
url("../fonts/flaticon.woff?f292c07b15c77b4e938a4b83b18f04e8") format("woff"),
url("../fonts/flaticon.woff2?f292c07b15c77b4e938a4b83b18f04e8") format("woff2"),
url("../fonts/flaticon.eot?f292c07b15c77b4e938a4b83b18f04e8#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?f292c07b15c77b4e938a4b83b18f04e8#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-left-quote:before {
    content: "\f101";
}
.flaticon-placeholder:before {
    content: "\f102";
}
.flaticon-telephone:before {
    content: "\f103";
}
.flaticon-send:before {
    content: "\f104";
}
.flaticon-location:before {
    content: "\f105";
}
.flaticon-email:before {
    content: "\f106";
}
.flaticon-house:before {
    content: "\f107";
}
.flaticon-phone-call:before {
    content: "\f108";
}
.flaticon-user:before {
    content: "\f109";
}
.flaticon-comment-white-oval-bubble:before {
    content: "\f10a";
}
.flaticon-calendar:before {
    content: "\f10b";
}
.flaticon-facebook-app-symbol:before {
    content: "\f10c";
}
.flaticon-twitter:before {
    content: "\f10d";
}
.flaticon-instagram:before {
    content: "\f10e";
}
.flaticon-linkedin:before {
    content: "\f10f";
}
.flaticon-youtube:before {
    content: "\f110";
}
.flaticon-play:before {
    content: "\f111";
}
.flaticon-maps-and-flags:before {
    content: "\f112";
}
.flaticon-instagram-1:before {
    content: "\f113";
}
.flaticon-quotation:before {
    content: "\f114";
}
.flaticon-pinterest:before {
    content: "\f115";
}
.flaticon-email-1:before {
    content: "\f116";
}
.flaticon-shopping-cart:before {
    content: "\f117";
}
.flaticon-search:before {
    content: "\f118";
}
.flaticon-phone-call-1:before {
    content: "\f119";
}
.flaticon-next:before {
    content: "\f11a";
}
.flaticon-left-arrow:before {
    content: "\f11b";
}
.flaticon-left-arrow-1:before {
    content: "\f11c";
}
.flaticon-right-arrow:before {
    content: "\f11d";
}
.flaticon-left-arrow-2:before {
    content: "\f11e";
}
.flaticon-right-arrow-1:before {
    content: "\f11f";
}
.flaticon-checked:before {
    content: "\f120";
}
.flaticon-play-button:before {
    content: "\f121";
}
.flaticon-mail:before {
    content: "\f122";
}
.flaticon-location-1:before {
    content: "\f123";
}
.flaticon-google-plus:before {
    content: "\f124";
}
.flaticon-right-quote-sign:before {
    content: "\f125";
}
.flaticon-email-2:before {
    content: "\f126";
}
.flaticon-telephone-1:before {
    content: "\f127";
}
.flaticon-click:before {
    content: "\f128";
}
.flaticon-web-design:before {
    content: "\f129";
}
.flaticon-customer-service:before {
    content: "\f12a";
}
.flaticon-24-hours-support:before {
    content: "\f12b";
}
.flaticon-install:before {
    content: "\f12c";
}
.flaticon-right-arrow-2:before {
    content: "\f12d";
}
.flaticon-right-arrows:before {
    content: "\f12e";
}
.flaticon-quote:before {
    content: "\f12f";
}
.flaticon-play-1:before {
    content: "\f130";
}
.flaticon-coffee-cup:before {
    content: "\f131";
}
.flaticon-guarantee:before {
    content: "\f132";
}
.flaticon-diamond:before {
    content: "\f133";
}
.flaticon-smile:before {
    content: "\f134";
}
.flaticon-image:before {
    content: "\f135";
}
.flaticon-video:before {
    content: "\f136";
}
.flaticon-quote-1:before {
    content: "\f137";
}
.flaticon-shopping-bag:before {
    content: "\f138";
}
.flaticon-play-button-1:before {
    content: "\f139";
}
.flaticon-gift-box:before {
    content: "\f13a";
}
.flaticon-atomic-structure:before {
    content: "\f13b";
}
.flaticon-eyedropper:before {
    content: "\f13c";
}
.flaticon-briefcase:before {
    content: "\f13d";
}
.flaticon-car:before {
    content: "\f13e";
}
.flaticon-lighting:before {
    content: "\f13f";
}
.flaticon-protection:before {
    content: "\f140";
}
